<template>
  <a-spin :spinning="spinningLoadingPage">
    <div>
      <b-card title="Quality Control - Configuration">
        <div class="custom-search ml-1 mr-1">
          <!-- advance search input -->
          <b-row class="mb-1" style="background-color: #ecf3f8; border-radius: 5px;">
            <b-col cols="11" class="mt-1">
              <b-row>
                <b-col md="4">
                  <b-form-group label-cols="10" label-cols-lg="3" label="Usage :" label-for="search-usage">
                    <!-- <b-form-select v-model="usageSelected" :options="usageOptions" v-on:change="getSelectedUsage" /> -->
                    <v-select 
											id="search-usage"
											v-on:input="getSelectedUsage(`${usageSelected.value}`)"
											v-model="usageSelected" 
											:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
											:options="usageOptions" 
											class="select-size-sm" 
											style="margin-top:-2px; background:white" 
										/>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-cols="10" label-cols-lg="3" label="Province :" label-for="search-province">
                    <!-- <b-form-select v-model="propinsiSelected" :options="propinsiOptions" v-on:change="getSelectedPropinsi" /> -->
                    <v-select 
											id="search-province" 
											v-on:input="getSelectedPropinsi(`${propinsiSelected.value}`)"
											v-model="propinsiSelected" 
											:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" 
											:options="propinsiOptions" 
											class="select-size-sm" 
											style="margin-top:-2px; background:white" 
										/>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label-cols="10" label-cols-lg="3" label="Search :" label-for="search-search">
                    <b-form-input id="search-search" v-model="searchText" placeholder="Search" type="text" class="d-inline-block" v-on:keyup="isStationID" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="1" class="mt-1 text-center">
              <b-button variant="primary" class="btn-icon" v-on:click="searchMetadata" :disabled="!enabledSearch">
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-col>
          </b-row>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :isLoading="isLoading"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
            setCurrentPage: paginationCurrentPage,
          }"
          @on-row-click="onRowClick"
          @on-cell-click="onCellClick"
          @on-page-change="onPageChange"
          theme="polar-bear"
        >
          <template slot="table-row" slot-scope="props">
            
            <span v-if="props.column.field === 'station_wmo_id'" class="text-nowrap">
              <a v-if="searchByUsage" href="#">{{ props.row.usage_station_id }}</a>

							<a v-else-if="props.row.station_wmo_id == 0" href="#">{{ props.row.is_poshujan ? props.row.pos_hujan_id : props.row.station_id }}</a>

							<a v-else-if="props.row.station_wmo_id != 0" href="#">{{ props.row.station_wmo_id }}</a>

							<a v-else href="#">{{ props.row.station_id }}</a>
            </span>
          </template>
        </vue-good-table>

      </b-card>
    </div>
  </a-spin>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol, BCard, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import metadata from "@/api/metadata";
import useJwt from "@/auth/jwt/useJwt";
import VueHtml2pdf from "vue-html2pdf";
// import ReportStasiun from "./ReportStasiun.vue";
import DataStasiun from "@/api/store/DataMetadata";
import User from "@/api/userservice";
import vSelect from "vue-select";
export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BCard,
    BButton,
    VueHtml2pdf,
    // ReportStasiun,
    DataStasiun,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      paginationCurrentPage: 1,
      pageLength: 10,
      dir: false,
      isLoading: true,
      columns: [
        {
					label: 'Station ID',
					field: 'station_wmo_id',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
				{
					label: 'Station Name',
					field: 'station_name',
					tdClass: 'text-left',
					thClass: 'text-center theadc',
				},
				{
					label: 'Province',
					field: 'propinsi_name',
					tdClass: 'text-center',
					thClass: 'text-center theadc',
				},
        // {
        //   label: "Summary",
        //   field: "summary",
        //   tdClass: "text-center",
        //   thClass: "text-center theadc",
        // },
      ],
      userType: "",
      ids: "",
      rows: [],
      searchTerm: "",
      searchText: "",
      searchByUsage: false,
      usageSelected: "",
      usageOptions: [
        { value: "", text: "-" },
        { value: "is_mkg", text: "MKG" },
        { value: "is_poshujan", text: "Pos Hujan" },
        { value: "is_kualitas_udara", text: "Kualitas Udara" },
        { value: "is_peralatan_otomatis", text: "AWS Center" },
        { value: "is_radar", text: "Radar" },
        { value: "is_percepatan_tanah", text: "Percepatan Tanah" },
        { value: "is_gempa", text: "Gempa" },
        { value: "is_bmkgentry", text: "BMKG Entry" },
      ],
      propinsiSelected: "",
      propinsiOptions: [{ value: "", text: "-" }],
      paramsStation: "",
      REPORT_ORIENTATION: "portrait",
      STORE_REPORT: null,
      spinningLoadingPage: false,
      filePdfName: "",
      htmlToPdfOptions: {
        margin: 17,
      },
      enabledSearch: false,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    // this.$http.get('/good-table/advanced-search')
    // .then(res => { this.rows = res.data })
  },
  methods: {
    isStationID(event) {
      let text = this.searchText
      if (text === "") {
        this.enabledSearch = true
      }
      if (!isNaN(text)) {
        if (text.length >= 5) {
          this.enabledSearch = true
          return event.preventDefault();
        }
        else if (text.length == 5) {
          this.enabledSearch = true
        }
        else {
          this.enabledSearch = false
        }
      }
      else {
        this.enabledSearch = true
      }
    },


    isSearchTextEnabled(text) {
      if (text === "") {
        return true
      }
      if (!isNaN(text)) {
        if (text.length >= 5) {
          return true
        }
        else if (text.length == 5) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return true
      }
    },

    advanceSearch(val) {
      this.searchTerm = val;
    },
    onRowClick(params) {},

    beforeDownload($event) {},
    onProgress($event) {},
    hasStartedGeneration() {},
    hasGenerated($event) {},

    searchMetadata() {
      let usage = (this.usageSelected !== null && this.usageSelected !== '') ? this.usageSelected.value : ''
			let propinsi = (this.propinsiSelected !== null && this.propinsiSelected !== '') ? this.propinsiSelected.value : ''
			let search = this.searchText
			let isUsage = false

			this.paramsStation = ''

			if (usage && usage != '') {
				// this.paramsStation = "&"+usage+"=true"
				isUsage = true
				this.paramsStation = "&usage_name="+usage+"&usage_status=true"
				this.searchByUsage = (usage == "MKG") ? false : true
			}
			else {
				this.searchByUsage = false
			}

			if (propinsi && propinsi != '') {
				this.paramsStation = this.paramsStation + "&propinsi_id="+propinsi
			}

			if (search && search != '') {
				let isStationName = isNaN(search)

				if(isStationName) {
					this.paramsStation = this.paramsStation  + "&station_name="+search
				}
				else {
					this.paramsStation = this.paramsStation  + "&station_wmo_id="+search
				}
				
			}
      this.paginationCurrentPage = 1
			if (this.paramsStation != '') {
				this.rows = [];
				this.listAllStasiun(0, 10000, this.paramsStation, this.userType, this.ids, isUsage);
			}
			else {
				this.rows = [];
				this.listAllStasiun(0, 200, this.paramsStation, this.userType, this.ids, isUsage);
			}
    },

    onReportStasiun(path, station_id, station_name) {
      this.filePdfName = "Report " + station_name;
      this.STORE_REPORT = null;
      var report = {};
      report.title = "STASIUN REPORT";

      this.spinningLoadingPage = true;

      new Promise(async (resolve) => {
        report.observer = await User.getObserverbyStationId(station_id);

        const { data } = await DataStasiun.storeDataMetadata(path);
        report.data = data;

        if (data.items && data.items.length > 0) {
          const { data } = await metadata.listMetadataUsage(path);
          report.usages = data;
        }

        let geography = await metadata.listMetadataGeography(path);
        report.geography = geography.data;

        this.STORE_REPORT = report;

        setTimeout(() => {
          this.$refs.html2Pdf.generatePdf();
          this.spinningLoadingPage = false;
        }, 1000);
        resolve(report);
      });
    },

    onCellClick(params) {
      if (params.column.field == "summary") {
        this.onReportStasiun(params.row.path, params.row.station_id, params.row.station_name);
      } else {
        this.$router.push({
          name: "qc-station",
          query: {
            path: params.row.path,
            content: params.row,
          },
        });
      }
    },

    onPageChange(params) {
      this.paginationCurrentPage = params.currentPage
			let startId = params.currentPage * params.currentPerPage
			let endId = startId + 200
			if (params.currentPage >= params.total &&  this.paramsStation == '') {
				this.listAllStasiun(startId, endId, this.paramsStation, this.userType, this.ids, false)
			}
    },

    getSelectedUsage: function(val) {
      this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false;
    },

    getSelectedPropinsi: function(val) {
      this.enabledSearch = this.isSearchTextEnabled(this.searchText) ? true : false;
    },

    async listAllPropinsi() {
      const { data: propinsi_data } = await metadata.getPropinsiList();
			let propinsiList = propinsi_data.sort((a, b) => {
									let fa = a.propinsi_name.toLowerCase()
									let fb = b.propinsi_name.toLowerCase()

									if (fa < fb) {
										return -1;
									}
									if (fa > fb) {
										return 1;
									}
									return 0;
								});
			propinsiList.forEach((item) => {
				const propinsi_item = {
					value: item.propinsi_id,
					label: item.propinsi_name,
				};
				this.propinsiOptions.push(propinsi_item);
			});
    },

    async onPropinsiChange() {
      let propinsiid = this.propinsi;
      // console.log('propinsiid: ', propinsiid)
      const { data: kabupaten_data } = await metadata.getKabupatenListByPropinsi(propinsiid);
      kabupaten_data.forEach((item) => {
        const kabupaten_item = {
          value: item.kabupaten_id,
          text: item.kabupaten_name,
        };
        this.options_kabupaten.push(kabupaten_item);
      });
    },

    onKabupatenChange() {},

    async listAllStasiun(startId, endId, options, userType, ids, isUsage) {
      this.isLoading = true
			try {
				const { data } = await metadata.getAllStationWithParams(startId, endId, options, userType, ids, isUsage);
				
				if (!Array.isArray(data)) {
					this.rows.push(...data.items)
				}
				else {
					data.forEach(function (item, index) {
						let path = item.path;
						if (path) {
							item.path = path.split('usage')[0]
						}
					});
					this.rows.push(...data)
				}
				// if (userType == 'root') {
				// 	this.rows.push(...data.items)
				// }
				// else {
				// 	this.rows.push(...data)
				// }
				

				// this.rows.push(...data.items)

				this.rows.sort(function(a, b){
					return a.station_id - b.station_id
				});

			} catch (error) {
				console.log('error', error)
				// if (error.hasOwnProperty('response') && error.response.hasOwnProperty('status') && error.response.status != undefined && error.response.status === 401) {
					// this.$swal({
					// 		title: "Sesi anda telah habis.",
					// 		text: "Logout dan silahkan login kembali",
					// 		icon: "warning",
					// 		confirmButtonText: "OK",
					// 		allowOutsideClick: false,
					// 		allowEscapeKey: false,
					// 		customClass: {
					// 			confirmButton: "btn btn-primary",
					// 		},
					// 		buttonsStyling: false,
					// 	}).then((result) => {
					// 		localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
					// 		localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
					// 		localStorage.removeItem('userData')
					// 		this.$router.push({ name: 'auth-login' })						
					// 	});
				// }
			}

			this.isLoading = false
    },

    async listManageUsage() {
      try {
				const { data } = await metadata.getListManageUsage();
				this.usageOptions = [];
				let usagesList = data.items.sort((a, b) => {
									let fa = a.manage_usage_name.toLowerCase()
									let fb = b.manage_usage_name.toLowerCase()

									if (fa < fb) {
										return -1;
									}
									if (fa > fb) {
										return 1;
									}
									return 0;
								});
				for (var i = 0; i < usagesList.length; i++) {
					if (usagesList[i].manage_usage_status) {
						const row = {
							value: usagesList[i].manage_usage_name,
							label: usagesList[i].manage_usage_name
						}
						this.usageOptions.push(row)
					}
					
				}

				this.usageSelected = ""
				
			} catch (e) {
				console.log(e);
			}
    },
  },
  mounted() {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    this.userType = userLogin.username;

    if (userLogin.hasOwnProperty("groupIds")) {
      this.ids = userLogin.groupIds;
    }

    this.usageOptions = [{ value: "", text: "-" }];
    this.listManageUsage();
    this.listAllPropinsi();

    this.rows = [];
    this.paramsStation = "";
    this.listAllStasiun(1, 200, this.paramsStation, this.userType, this.ids, false);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
.vgt-table thead th.theadc {
  background-color: #f7fafc !important;
}
</style>
