// import DataHelper from "@/api/dummy/store/DataHelper";
import axios from "axios";
import Helper from "@/api/helper";

var SHOW_DUMMY_METADATA = false;
export default {
    async storeDataMetadata(path){
        return  new Promise((resolve) => {
            var url = Helper.getBaseUrl() + path;
            var data = axios.get(url, Helper.getConfig())
            resolve(data);
        });
    },
}